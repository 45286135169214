import React, { useEffect, useState } from "react";
import "../Styles/HomeMain.css";

import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Carousels from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Aos from "aos";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { GrServices } from "react-icons/gr";
import { PiClockUser } from "react-icons/pi";
import Nav from 'react-bootstrap/Nav';
import axios from "axios";
import parse from "html-react-parser";

// _________++++++++++++++++___________

import OwlCarousel from 'react-owl-carousel';
// import EnqForm from "../EnqForm";

import EnqForm from "./EnqForm";
// import FooterMain from "../Footer/FooterMain";

import FooterMain from "../Components/FooterMain"
import { useNavigate } from "react-router-dom";


const HomeMain = () => {

  const navigate = new useNavigate(); //to navigate to the required page

  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {

      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      }

    },

  };

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0)
  })

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 600,
      delay: 100,
    });
    window.scroll(0, 0);
  }, []);


  //GET (Home Banner)

  const [Banner, setBanner] = useState([]);

  const getHomeBanner = async () => {

    try {
      let res = await axios.get("https://students-databse.in/api/homebanner/gethome");
      if (res.status === 201) {
        setBanner(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //GET (Home Feature)

  const [HomeFeature, setHomeFeature] = useState([]);

  const getHomeFeature = async () => {

    try {
      let res = await axios.get("https://students-databse.in/api/homefeature/getfeature");
      if (res.status === 201) {
        setHomeFeature(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //GET (Home Whychooseus)

  const [Whychooseus, setWhychooseus] = useState([]);

  const getWhychooseus = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/homewhychooseus/getwhychooseus");
      if (res.status === 201) {
        setWhychooseus(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  console.log("why", Whychooseus);

  useEffect(() => {
    getHomeBanner();
    getHomeFeature();
    getWhychooseus();
  }, []);

  //GET (Service List Contents)

  const [serviceCategorylist, setServiceCategoryList] = useState([])
  const [Category, setCategory] = useState([])

  const getServiceList = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/service/getservicecategorydetails");
      if (res.status === 201) {
        setServiceCategoryList(res.data.data1);
        setCategory(res.data.data1);
      }
      if (res.status === 500) {
        alert("Something went wrong..")
      }
    }
    catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    getServiceList()
  }, [])


  //extra added (To filter Based on Particular Selected Category)

  const [SelectedCategory, setSelectedCategory] = useState("");

  const [FilteCategory, setFilteCategory] = useState([])


  // Initialize category from location.state or default to an empty string

  useEffect(() => {
    if (SelectedCategory) {
      const filterdata = FilteCategory?.filter((ele) => ele.category === SelectedCategory)
      setServiceCategoryList(filterdata)
    }
  }, [SelectedCategory, FilteCategory]);


  return (

    <div className="home-maindiv">

      {/*=== Fade Carousel ===*/}

      <div data-aos="fade-up">
        <Carousel fade>

          {Banner?.map((item) => {
            return (

              <Carousel.Item>
                {/* <img src="/Assets/home-c1.jpg" className="carousel-img-main" /> */}
                <img
                  src={`https://students-databse.in/HomeBanner/${item?.bannerImg}`}
                  className="carousel-image-mobileres"
                  alt=""
                  height="400px"
                  width="100%"

                />
                <Carousel.Caption>
                  <h3 data-aos="fade-right" data-aos-duration="3000">{item?.bannerText}</h3>
                  <div className="carousel-div-button">
                    {/* <button>Know More</button> */}
                  </div>
                  {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>


            );
          })}
        </Carousel>
      </div>

      {/*==== Welcome To Ekadantha Automation ====*/}

      <p className="welcome text-center mt-5 mb-3" data-aos="zoom-in" data-aos-duration="1200">Welcome To Ekadantha Automation</p>

      {HomeFeature?.map((item, i) => {
        return (

          <div className="welcome-container-box mt-3 " data-aos="zoom-in" data-aos-duration="1200">
            <p className="welcome-description text-white">
              <td style={{ paddingTop: "10px" }}>{parse(item?.description)}</td>
            </p>
            <button className="viewmore-btn mb-5"><a href="/about" className="view-btn-href">View More</a></button>
          </div>

        );
      })}


      {/*==== Our Sales & Services ====*/}

      <p className="sales-services text-center mt-5 mb-3">Our Sales and Services</p>

      {/* To get only unique service name (to remove duplicate service category) */}

      {[...new Set(Category.map(item => item.category))]?.map((uniqueCategory, index) => (

        <div className="sales-service-box mb-3">

          <div className="row" style={{ width: "100%" }}>
            <span className="fs-2 text-start text-white service-heading1">{uniqueCategory}</span>
            <button className="viewmore-btnn"><a href="/service" className="viewmore-btnn-href">View More</a></button>
          </div>

          <Container>
            <div className='row mt-3 mb-5'>
              <div className='col-md-12'>
                <OwlCarousel className='owl-theme' loop={false} margin={8} {...options}>

                  {/* To filter by unique selected category (service category contents) */}

                  {Category?.filter((ele) => ele.category === uniqueCategory)?.map((item) => {

                    return (
                      <div class='item'>
                        <Card className='testimonial-crd'>

                          <Card.Body>
                            <div>

                              <div>
                                <img src={`https://students-databse.in/ServiceCategoryDetails/${item?.image}`} alt="" className='testimonial-img1' />
                              </div>

                              <div
                                className='name'
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/service", { state: { item: item } })}
                              ><b> {item?.subtitle} </b></div>

                            </div>
                          </Card.Body>

                        </Card>
                      </div>

                    )
                  })}

                </OwlCarousel>


              </div>

            </div>
          </Container>

        </div>

      ))}


      {/* Why Choose Us */}

      <p className="whychoose-us text-center mt-5 mb-3" >Why Choose Us</p>

      <div className="whycooseus-box">
        <OwlCarousel className="owl-theme" {...options}>

          {Whychooseus?.map((item, i) => {

            return (

              <div className="item">

                <div className="card" style={{ width: "18rem", paddingTop: '5px' }}>

                  {/* <img className="card-img-top whychoose-icons" src="../Assets/icon1.png" alt="Card image cap"/> */}

                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <img className="card-img-top whychoose-icons" src={`https://students-databse.in/Whychooseus/${item?.image}`} alt="pic" />
                  </div>

                  <div className="card-body customeCard  d-flex justify-content-center flex-column align-items-center  overflow-hidden">
                    <h5 className="card-title text-center " >{item?.text}</h5>
                    <p className="card-text text-center" style={{ width: '270px' }} >{parse(item?.description)}</p>
                  </div>
                </div>
              </div>

            );
          })}

        </OwlCarousel>
      </div>

    </div>
  );
};

export default HomeMain;