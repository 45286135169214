import React, { useState, useEffect } from 'react';
import "../Styles/Service.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card } from 'react-bootstrap';
import axios from 'axios'

import EnqForm from "./EnqForm";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsDatabaseDown } from 'react-icons/bs';
import parse from "html-react-parser";
import { useLocation } from 'react-router-dom';
import { BsSearch } from "react-icons/bs";
// import Aos from 'aos';


const Service = () => {

    // Get Selected Category (To get the data based on particular category)

    const location = useLocation();
    const { item } = location.state || {};
    console.log("item", item);

    //MODAL (Functionality for viewing the model)

    const [showMore, setShowMore] = useState(false);
    const [show, setShow] = useState(false);

    const [selectedObj, setSelectedObj] = useState({})
  
    const handleClose = () => setShow(false);

    const handleShow = (data) => {
        setShow(true);
        setSelectedObj(data)
    }


    //GET (Service Category Contents)

    const [FilteCategory, setFilteCategory] = useState([]) //to filter particular category

    const [Category, setCategory] = useState([]) //service category text

    const [serviceCategorylist, setServiceCategoryList] = useState([]) //service catagory contents

    const getServiceList = async () => {

        try {
            let res = await axios.get("https://students-databse.in/api/service/getservicecategorydetails");
            if (res.status === 201) {
                setServiceCategoryList(res.data.data1);
                setCategory(res.data.data1);
                setFilteCategory(res.data.data1);
            }
            if (res.status === 500) {
                alert("Something went wrong..")
            }
        }
        catch (error) {
            console.log(error);

        }
    }

    console.log("serviceCategorylist", serviceCategorylist);


    //GET (service description text)

    const [serviceDesc, setServiceDescription] = useState([])

    const getServiceDescription = async () => {
        try {
            let res = await axios.get("https://students-databse.in/api/service/getdescription");
            if (res.status === 201) {
                setServiceDescription(res.data.data1);
            }
            if (res.status === 500) {
                alert("Something went wrong..")
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getServiceList()
        getServiceDescription()
    }, [])



    //============To filter based on the selected category=================//

    const [SelectedCategory, setSelectedCategory] = useState("");

    // Initialize category from location.state or default to an empty string

    useEffect(() => {
        if (SelectedCategory) {
            const filterdata = FilteCategory?.filter((ele) => ele.category === SelectedCategory)
            setServiceCategoryList(filterdata)
        }
    }, [SelectedCategory, FilteCategory]);


    // Search filter

    const [nochangedata, setNoChangeData] = useState([]);
    const [searchH, setSearchH] = useState("");

    const handleFilterH = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchH(searchTerm); // Update search term state

        if (searchTerm !== "") {
            // Filter based on specific keys (e.g., subtitle, description)
            const filteredData = serviceCategorylist.filter((item) =>
                item.subtitle.toLowerCase().includes(searchTerm) ||
                item.description.toLowerCase().includes(searchTerm)
            );
            setServiceCategoryList(filteredData); // Set filtered data
        } else {
            setServiceCategoryList(nochangedata);
        }
    };


    return (
        <div>
            <section className='about-bannerimg'
                style={{
                    backgroundImage: `url("/Assets/banner.jpg")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%"
                }}
            >


                <div className="container ">
                    <div className='breadcrumbt'>
                        <div>
                            <h1 className='abtus-heading'>Service</h1>
                        </div>
                        <div className='mbl-responsive-breadcrumbs'>
                            <Breadcrumb>
                                <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding: "5px" }} />
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active className='abtus-small-heading' style={{ color: "white" }}>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
            </section>

            {/* Service Description */}

            <Container>
                {
                    serviceDesc.map((data) => {
                        return (
                            <div className='mt-5 mb-5 service-desctext' >{parse(`<div>${data?.description}</div>`)}</div>
                        )
                    })
                }
            </Container>

            {/* Service Category */}

            <Container>
                <div className='row mt-5 mb-5'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                      
                            <Col sm={4} style={{ position: "relative" }} className='service-categorycard-mbl'>

                                <Card className="text-center service-category-card" style={{ position: "sticky", top: "150px" }}>

                                    <Card.Header className="custom-card-header">
                                        <b className='text-white'>Service List</b>
                                    </Card.Header>

                                    <Card.Body className='d-flex flex-column justify-content-center align-items-center service-list-card-contents'>
                                        <Nav variant="pills" className="flex-column" style={{ width: '300px' }}>

                                            {
                                                Array.isArray(Category) && Category.length > 0 ? (

                                                    // Create a new array with unique categories using Set

                                                    [...new Set(Category.map(item => item.category))].map((uniqueCategory, index) => (

                                                        <Nav.Item key={index}>
                                                            <Nav.Link
                                                                eventKey={index}
                                                                className='service-heading rounded-0'
                                                                onClick={() => setSelectedCategory(uniqueCategory)}
                                                            >
                                                                {uniqueCategory}
                                                            </Nav.Link>
                                                        </Nav.Item>

                                                    ))
                                                ) : (
                                                    <p>No categories available.</p> // Fallback in case there's no data
                                                )
                                            }

                                        </Nav>
                                    </Card.Body>
                                </Card>
                            </Col>

              {/* Service Category Contents */}

                            <Col sm={8}>
                   

                                    <div className=' gap-2 service-mainbox w-100'>
                                    <p className='cardd-mainheading text-white'>{SelectedCategory}</p>
                                        <div className=''>
                                            

                                            {/* <input type='text' placeholder='Search' className='search-input' /> */}

                                            {/* Search */}

                                            <div className="servicedt justify-content-center">

                                                <div className="input-group bbb">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <BsSearch />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleFilterH}
                                                    />
                                                </div>

                                            </div>

                                            {/* Apply map method for the product that comes under service category*/}

                                            {serviceCategorylist?.map((data) => {
                                                return (

                                                    <div className='row service-contents-card mt-3 mb-3'>

                                                        <div className='col-md-4 mb-3' data-aos="zoom-in" data-aos-duration="3000">

                                                            {/* for the zooming of image when we click on it */}

                                                            <a href={`https://students-databse.in/ServiceCategoryDetails/${data?.image}`} target='_blank'>

                                                            <img
                                                                className="service-largeimg"
                                                                src={`https://students-databse.in/ServiceCategoryDetails/${data?.image}`}
                                                                alt="pic"
                                                            />

                                                            </a>

                                                        </div>

                                                        <div className='col-md-8'>
                                                            <h5 className="card-title mt-2 mb-2 text-white">{data?.subtitle}</h5>

                                                            <p className="card-text mb-3 text-white serviceDesc">
                                                                {parse(`<div>${data?.description}</div>`)}
                                                            </p>

                                                            {/* Using productid to get product and service name on admin panel (i.e., to bring the service name and product name in admin panel on clicking on submit in service enquiry form)*/}

                                                            <EnqForm productid={data?._id} /> 

                                                            <button className="btn btn-secondary service-view-more" onClick={() => handleShow(data)}>
                                                                View More
                                                            </button>
                                                        </div>

                                                    </div>
                                                )
                                            })}

                                        </div>

                                    
                                    </div>


                               
                            </Col>
                       
                    </Tab.Container>
                </div>
            </Container>


            {/* Modal for Service Description*/}


            <Modal show={show} onHide={handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>{selectedObj?.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h6>{selectedObj?.subtitle}</h6>
                    <br />
                    <p>
                        {parse(`<div>${selectedObj?.description}</div>`)}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>

            </Modal>
        </div>
    );
};


export default Service;
