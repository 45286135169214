import React, { useState, useEffect } from 'react';
import "../Styles/Service.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card } from 'react-bootstrap';
import axios from 'axios'
import EnqForm from "./EnqForm";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsDatabaseDown } from 'react-icons/bs';
import parse from "html-react-parser";
import { useLocation } from 'react-router-dom';

 import Aos from 'aos';

const Project = () => {

    const [showMore, setShowMore] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedObj, setSelectedObj] = useState({})

    const handleClose = () => setShow(false);

    const handleShow = (data) => {
        setShow(true);
        setSelectedObj(data)
    }

    //GET (service description)

    const [FilteCategory, setFilteCategory] = useState([])

    const [Category, setCategory] = useState([]) //project category text

    const [projectCategorylist, setProjectCategoryList] = useState([])

    const getServiceList = async () => {
        
        try {
            let res = await axios.get("https://students-databse.in/api/project/getprojectcategorydetails");

            if (res.status === 201) {
                setProjectCategoryList(res.data.data1);
                setCategory(res.data.data1);
                setFilteCategory(res.data.data1);
            }

            if (res.status === 500) {
                alert("Something went wrong..")
            }
        }
        catch (error) {
            console.log(error);

        }
    }

    console.log("projectCategorylist", projectCategorylist);


    //GET (service description text)

    // const [serviceDesc, setServiceDescription] = useState([])

    // const getServiceDescription = async () => {
    //     try {
    //         let res = await axios.get("https://students-databse.in/api/service/getdescription");
    //         if (res.status === 201) {
    //             setServiceDescription(res.data.data1);
    //         }
    //         if (res.status === 500) {
    //             alert("Something went wrong..")
    //         }
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // }

    //GET (Project Description)

  const [ProjectDescription, setProjectDescription] = useState([]);

  const getProjectDescription = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/project/getprojectdescription");
      if (res.status === 201) {
        setProjectDescription(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

    useEffect(() => {
        getServiceList()
        //getServiceDescription()
        getProjectDescription()
    }, [])

    
    //extra added
    const [SelectedCategory, setSelectedCategory] = useState("");

    // Initialize category

    useEffect(() => {
        if (SelectedCategory) {
            const filterdata = FilteCategory?.filter((ele) => ele.projectcategory === SelectedCategory)
            setProjectCategoryList(filterdata)
        }
    }, [SelectedCategory, FilteCategory]);

    //Animation

    useEffect(() => {
        Aos.init();
        window.scroll(0, 0)
      })    


    return (
        <div>

            {/* Service Banner */}

            <section className='about-bannerimg'
                style={{
                    backgroundImage: `url("/Assets/banner.jpg")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%"
                }}
            >

                <div className="container">
                    <div className='breadcrumbt'>
                        <div>
                            <h1 className='abtus-heading'>Project</h1>
                        </div>
                        <div className='mbl-responsive-breadcrumbs'>
                            <Breadcrumb>
                                <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding: "5px" }} />
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active className='abtus-small-heading' style={{ color: "white" }}>Project</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
            </section>


            {/* Project Description */}


            <Container>

                {
                    ProjectDescription.map((data) => {
                        return (
                            <div className='mt-5 mb-2 project-desctext' >{parse(`<div>${data?.projectdescription}</div>`)}</div>
                        )
                    })
                }

            </Container>

             {/* Project Category */}

            <Container>
                <div className='row mt-5 mb-5'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                        <Row>
                            <Col sm={4} style={{ position: "relative" }}>

                                <Card className="text-center project-category-card" style={{ position: "sticky", top: "200px" }}>

                                    <Card.Header className="custom-card-header">
                                        <b className='text-white' style={{ position: "sticky", top: "150px" }}>Project List</b>
                                    </Card.Header>

                                    <Card.Body className='d-flex flex-column justify-content-center align-items-center'>
                                        <Nav variant="pills" className="flex-column" style={{ width: '300px' }}>

                                            {
                                                Array.isArray(Category) && Category.length > 0 ? (

                                                    // Create a new array with unique categories using Set

                                                    [...new Set(Category.map(item => item.projectcategory))].map((uniqueCategory, index) => (
                                                        <Nav.Item key={index}>
                                                            <Nav.Link
                                                                eventKey={index}
                                                                className='project-heading rounded-0'
                                                                onClick={() => setSelectedCategory(uniqueCategory)}
                                                            >
                                                                {uniqueCategory}
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))
                                                ) : (
                                                    <p>No categories available.</p> // Fallback in case there's no data
                                                )
                                            }

                                        </Nav>
                                    </Card.Body>
                                </Card>
                            </Col>

                        {/* Project Category Contents */}

                            <Col sm={8}>
                                <Tab.Content>

                                    <div className='col-md-8 d-flex gap-2 project-mainbox w-100'>
                                        <div className='row'>
                                            <p className='cardd-mainheading text-white'>{SelectedCategory}</p>

                                            {/* <input type='text' placeholder='Search' className='search-input' /> */}

                                            {/* Apply map method for the product that comes under service category*/}

                                            {projectCategorylist?.map((data) => {
                                                return (
                                                    <div className='row project-contents-card mt-3 mb-3'>

                                                        <div className='col-md-8'>
                                                            {/* <h5 className="card-title mt-2 mb-2 text-white">{data?.subtitle}</h5> */}
                                                            
                                                            <p className="card-text mb-3 text-white">
                                                                {parse(`<div>${data?.projectdescription}</div>`)}
                                                            </p>

                                                            {/* <EnqForm /> */}

                                                            {/* <button className="btn btn-secondary" onClick={() => handleShow(data)}>
                                                                View More
                                                            </button> */}
                                                        </div>

                                                        <div className='col-md-4 mb-3' data-aos="zoom-in" data-aos-duration="3000">

                                                            {/* for the zooming of image when we click on it */}

                                                            <a href={`https://students-databse.in/ProjectCategoryDetails/${data?.projectimage}`} target='_blank'>

                                                            <img
                                                                className="service-largeimg"
                                                                src={`https://students-databse.in/ProjectCategoryDetails/${data?.projectimage}`}
                                                                alt="pic"
                                                            />
                                                            
                                                            </a>
                                                        </div>

                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>


                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </Container>
            
            {/* Modal for Service Description*/}

            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedObj?.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <h6>{selectedObj?.subtitle}</h6>
                    <br />
                    <p>
                        {parse(`<div>${selectedObj?.description}</div>`)}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
             */}

        </div>
    );
};

export default Project;
